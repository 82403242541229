<script>
import { createEventDispatcher } from "svelte";

    import { _ } from "svelte-i18n";

    export let region;
    export let country;
    export let state;

    const dispatch = createEventDispatcher();
    const handleClick = (filter) => {
        dispatch(filter);
    }
</script>

<div class="sc__breadcrumb">
    {#if region}
    <button class="sc__breadcrumb__item" on:click={() => handleClick('region')}>{$_('contacts.regions')}</button>
        {#if country}
        <button class="sc__breadcrumb__item" on:click={() => handleClick('country')}>{$_('contacts.country')}</button>
            {#if state}
            <button class="sc__breadcrumb__item" on:click={() => handleClick('state')}>{$_('contacts.state')}</button>
            {/if}
        {:else}
        <span>{$_('contacts.selectCountry')}</span>
        {/if}
    {:else}
    <span>{$_('contacts.selectRegion')}</span>
    {/if}
</div>