<script>
    export let headquarters = [];
    export let list;
</script>

<ul class="sc__headquarter__list" bind:this={list}>
    {#each headquarters as hq}
    <li class="sc__headquarter">
        <div class="sc__headquarter__title">{hq.name}</div>
        <div class="sc__headquarter__address">{@html hq.address}</div>
        <div class="sc__headquarter__reference">{@html hq.reference}</div>
        <div class="sc__headquarter__info">{@html hq.info}</div>
    </li>
    {/each}
</ul>