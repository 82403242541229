<script>
    import Carousel from './Carousel.svelte';

    export let contacts;
    export let region;

    const getContactTypes = (contacts) => {
        const types = [];
        contacts.forEach(el => {
            el.type.forEach(type => {
                if (!types.some(t => t.id === type.id)) {
                    types.push(type);
                }
            });
        });
        return types.filter(el => el.id !== 2).sort((a, b) => b.id - a.id);
    };

    let visibleContacts = [];
    $: if (contacts && !!contacts.length) {
        getContactTypes(contacts).forEach(type => {
            visibleContacts = [...visibleContacts, {
                type: type,
                data: contacts.filter(contact => contact.type.some(el => el.id === type.id))
            }];
        });
    }
    else { visibleContacts = []; }
</script>

<div class="sc__contact-list" class:list--empty="{!visibleContacts.length}">
{#if region}
    {#each visibleContacts as contact}
    <div class="sc__contact">
        <p class="sc__contact__type">{contact.type.label}</p>
        {#each contact.data as data}
        <div class="sc__contact__box">
            <div class="sc__contact__box__left">
                <div class="sc__contact__box__name">{data.name}</div>
                {#if data.address}<div class="sc__contact__box__address">{@html data.address}</div>{/if}
            </div>
            <div class="sc__contact__box__right">
                {#if data.reference}<div class="sc__contact__box__reference">{@html data.reference}</div>{/if}
                {#if data.info}<div class="sc__contact__box__info">{@html data.info}</div>{/if}
            </div>
        </div>
        {/each}
    </div>
    {/each}
{:else}
    <Carousel />
{/if}
</div>