import axios from "axios";

// Mocks - temp
// import MockAdapter from "axios-mock-adapter";
// import events from "../../../mocks/events.json";
// import countries from "../../../mocks/countries.json";
// import contacts from "../../../mocks/contacts.json";

// Initialization
const instance = axios.create({
    headers: { "Content-Type": "application/json", },
});

// Mocks - init
// var mock = new MockAdapter(instance);
// mock.onGet("/api/events").reply(200, events);
// mock.onGet("/api/countries").reply(200, countries);
// mock.onGet("/api/contacts").reply(200, contacts);

function setLocale(locale) {
    instance.defaults.headers["Accept-Language"] = locale;
}

function setEndpoint(url) {
    instance.defaults.baseURL = url;
}

async function getEvents(data) {
    return await instance.get("/api/events", { params: data });
}

async function getCountries() {
    return await instance.get("/api/countries");
}

async function getContacts(data) {
    return await instance.get("/api/contacts", { params: data });
}

async function getHeadquarters() {
    return await getContacts({ hq: 1 });
}

export default {
    setLocale,
    setEndpoint,
    getEvents,
    getCountries,
    getContacts,
    getHeadquarters,
};