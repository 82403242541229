<script>
    import moment from 'moment';
    import Flatpickr from 'svelte-flatpickr';

    export let events;
    export let date;

    let minDate, eventDates = [], overlapDates = [];
    $: options = {
        static: true,
        inline: true,
        defaultDate: date,
        enable: eventDates,
        onDayCreate: (dObj, dStr, fp, dayElem) => {
            dayElem.querySelectorAll('.sc__badge').forEach(e => e.remove());
            overlapDates.forEach(range => {
                const day = dayElem.dateObj;
                if (day >= range.from && day <= range.to) {
                    const count = eventDates.filter(el => el.from <= day && el.to >= day).length;
                    if (count > 1) { dayElem.innerHTML += '<span class="sc__badge">' + count + '</span>'; }
                    return;
                }
            });
        }
    };

    $: if (events) {
        eventDates = [];
        events.forEach(el => {
            const fromDate = moment(el.startDate).startOf('day').toDate(), toDate = moment(el.endDate).startOf('day').toDate();
            minDate = minDate ? (minDate < fromDate || fromDate < new Date() ? minDate : fromDate) : fromDate;
            eventDates = [...eventDates, { from: fromDate, to: toDate }];

            // Overlaps
            overlapDates = eventDates
                .sort((prev, curr) => {
                    const prevTime = prev.from.getTime(), currTime = curr.from.getTime();
                    if (prevTime < currTime) { return -1; }
                    else if (prevTime === currTime) { return 0; }
                    else { return 1; }
                })
                .reduce((result, curr, idx, arr) => {
                    if (idx === 0) { return result; }
                    const prev = arr[idx - 1], prevEnd = prev.to.getTime(), currStart = curr.from.getTime();
                    if (prevEnd >= currStart) { result.push({ from: curr.from, to: prev.to }); }
                    return result;
                }, []);
        });
    }
</script>

<div class="sc__calendar">
    <Flatpickr {options} bind:value={date} />
</div>