<script>
    import moment from 'moment';
    import { _ } from 'svelte-i18n';

    export let events;
    export let date;

    let filteredEvents = [];
    $: if (events && date) {
        filteredEvents = events.filter(el =>
            moment(el.startDate).startOf('day') <= moment(date)
            && moment(el.endDate).endOf('day') >= moment(date)
        );
    }
    else { filteredEvents = []; }

    const getDateRange = (start, end) => {
        return moment(start).format('DD MMM') + '-' + moment(end).format('DD MMM');
    };
</script>

<div class="sc__event-list">
    {#each filteredEvents as event}
    <div class="sc__event">
        <div class="sc__event__date">{getDateRange(event.startDate, event.endDate)}</div>
        <div class="sc__event__image" style="background-image: url('{event.image}');" />
        <div class="sc__event__content">
            <p class="sc__event__title">{event.title}</p>
            {#if event.description}<p class="sc__event__description">{event.description}</p>{/if}
            {#if event.link}<a class="sc__event__link" href={event.link} target="_blank">{$_('events.discoverMore')}</a>{/if}
        </div>
    </div>
    {/each}
</div>