import { init, addMessages, getLocaleFromNavigator } from 'svelte-i18n';
import en from './locales/en.json';
import it from './locales/it.json';
import fr from './locales/fr.json';
import sv from './locales/sv.json';
import pl from './locales/pl.json';
import br from './locales/br.json';
import pt from './locales/pt.json'; // Clonato dal br.json perché al momento non abbiamo il pt.json
import ja from './locales/ja.json';
import ko from './locales/ko.json';
import zh from './locales/zh.json';
import de from './locales/de.json';
import es from './locales/es.json';

import flatpickr from 'flatpickr';
import { Italian } from 'flatpickr/dist/l10n/it.js';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { Swedish } from 'flatpickr/dist/l10n/sv.js';
import { Polish } from 'flatpickr/dist/l10n/pl.js';
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'; // br.js non esiste su flatpickr
import { Japanese } from 'flatpickr/dist/l10n/ja.js';
import { Korean } from 'flatpickr/dist/l10n/ko.js';
import { Mandarin } from 'flatpickr/dist/l10n/zh.js';
import { German } from 'flatpickr/dist/l10n/de.js';
import { Spanish } from 'flatpickr/dist/l10n/es.js';

import Events from './svelte/Events.svelte';
import Headquarters from './svelte/Headquarters.svelte';
import Contacts from './svelte/Contacts.svelte';
import http from './svelte/utility/http.js';

import config from 'config';

function initLocale(locale) {
	//i18n
	addMessages('en', en);
	addMessages('it', it);
	addMessages('fr', fr);
	addMessages('sv', sv);
	addMessages('pl', pl);
	addMessages('br', br);
	addMessages('ja', ja);
	addMessages('ko', ko);
	addMessages('pt', pt);
	addMessages('zh', zh);
	addMessages('de', de);
	addMessages('es', es);
	init({
		fallbackLocale: 'en',
		initialLocale: locale || getLocaleFromNavigator(), // in genere sono le prime due lettere nell'url, tipo ja o pt
	});

	//Flatpickr
	if (locale === 'it') { flatpickr.localize(Italian); }
	else if (locale === 'fr') { flatpickr.localize(French); }
	else if (locale === 'sv') { flatpickr.localize(Swedish); }
	else if (locale === 'pl') { flatpickr.localize(Polish); }
	else if (locale === 'pt') { flatpickr.localize(Portuguese); }
	else if (locale === 'ja') { flatpickr.localize(Japanese); }
	else if (locale === 'ko') { flatpickr.localize(Korean); }
	else if (locale === 'zh') { flatpickr.localize(Mandarin); }
	else if (locale === 'de') { flatpickr.localize(German); }
	else if (locale === 'es') { flatpickr.localize(Spanish); }
	flatpickr.l10ns.default.firstDayOfWeek = 1;

	// HTTP
	http.setLocale(locale);
}

function bootstrap() {
	initLocale(config.locale);
	http.setEndpoint(config.endpoint);

	const events = document.querySelectorAll('[data-scope="salvagnini-events"]');
	events.forEach(target => {
		new Events({ target });
	});

	const headquarters = document.querySelectorAll('[data-scope="salvagnini-headquarters"]');
	headquarters.forEach(target => {
		new Headquarters({ target });
	});

	const contacts = document.querySelectorAll('[data-scope="salvagnini-contacts"]');
	contacts.forEach(target => {
		new Contacts({ target });
	});
}

window.addEventListener('DOMContentLoaded', bootstrap);