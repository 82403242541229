<script>
    import { onMount } from "svelte";

    import Alert from "./components/Alert.svelte";
    import Filters from "./components/contacts/Filters.svelte";
    import ContactList from "./components/contacts/ContactList.svelte";

    import http from "./utility/http";
    import '../scss/_main.scss';

    let root, messages = [];
    let regions = [], countries = [], contacts = [];

    let region, country, state;
    $: loadContacts(country, state);

    const loadContacts = async () => {
        contacts = [];
        if (country || state) {
            const req = {};
            if (state) { req.state = state; }
            else { req.country = country; }
            
            const response = await http.getContacts(req).catch(err => {
                messages = [...messages, {
                    type: 'error',
                    description: err.message,
                    statusCode: (err.response ? err.response.status : 503)
                }];
            });

            if (response && response.data) {
                contacts = response.data;
            }
        }
    }

    onMount(async () => {
        const response = await http.getCountries().catch(err => {
            messages = [...messages, {
                type: 'error',
                description: err.message,
                statusCode: (err.response ? err.response.status : 503)
            }];
        });

        if (response && response.data) {
            countries = response.data;
            countries.forEach(el => {
                if (!regions.some(region => region.id === el.region.id)) {
                    regions = [...regions, el.region];
                }
            });
            regions = regions.sort((a, b) => a.name.localeCompare(b.name));
        }
    });
</script>

<div class="sc__contacts" bind:this={root} on:click={() => root ? root.scrollIntoView(true) : null}>
    {#each messages as message}
        <Alert {message} />
    {/each}

    <div class="sc__contacts__wrapper">
        <Filters {regions} {countries} bind:region bind:country bind:state />
        <ContactList {region} {contacts} />
    </div>
</div>