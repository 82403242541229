<script>
    import { onMount } from "svelte";

    import config from 'config';

    let count = 0, interval = 5000;
    const images = [
        'Salvagnini-America.png',
        'Salvagnini-Industriale.png',
        'Salvagnini-Italia.png',
        'Salvagnini-Maschinenbau.png',
        'Salvagnini-Mexico.png',
        'Salvagnini-Robotica.png',
        'Salvagnini-Scandinavia.png'
    ];

    $: if (count === images.length) {
        count = 0;
    }

    onMount(() => {
        setInterval(() => { count++; }, interval);
    });
</script>

<div class="sc__carousel">
    {#each images as image, i}
    <img class="sc__carousel__image" class:image--active="{count === i}" alt="Carousel" src={config.imgPath + image} />
    {/each}
</div>