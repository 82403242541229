<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    
    import Alert from "./components/Alert.svelte";
    import HeadquarterList from "./components/headquarters/HeadquarterList.svelte";

    import http from "./utility/http";
    import '../scss/_main.scss';

    let messages = [];
    let headquarters = [];
    let list;

    function scrollTo(where) {
        if (list) {
            const elWidth = list.querySelector('.sc__headquarter').offsetWidth;
            const scrollPos = (where === 'prev' ? -1 : 1) * elWidth;
            list.scrollBy({
                left: scrollPos,
                behavior: 'smooth'
            });
        }
    }
    
    onMount(async () => {
        const response = await http.getHeadquarters().catch(err => {
            messages = [...messages, {
                type: 'error',
                description: err.message,
                statusCode: (err.response ? err.response.status : 503)
            }];
        });

        if (response && response.data) {
            headquarters = response.data;
        }
    });
</script>

<div class="sc__headquarters">
    {#each messages as message}
        <Alert {message} />
    {/each}

    <div class="sc__headquarters__header">
        <div class="sc__headquarters__title">{$_('headquarters.title')}</div>
        <div class="sc__headquarters__arrows">
            <button type="button" on:click={() => scrollTo('prev')} class="sc__arrow arrow--left"></button>
            <button type="button" on:click={() => scrollTo('next')} class="sc__arrow arrow--right"></button>
        </div>
    </div>

    <HeadquarterList bind:list {headquarters} />
</div>