<script>

    export let options;
    export let value;

</script>

<select class="sc__select" bind:value on:change>
    {#each options as option}
    <option value={option.value}>{option.label}</option>
    {/each}
</select>