<script>
    import Breadcrumb from "./Breadcrumb.svelte";

    export let regions;
    export let countries;

    export let region;
    export let country;
    export let state;

    let states = [];
    let visibleCountries = countries;

    const handleRegion = (id) => {
        region = id, country = null, state = null;
        visibleCountries = [], states = [];
        visibleCountries = countries.filter(el => el.region.id === id);
    };

    const handleCountry = (id) => {
        country = id, state = null;
        states = countries.find(el => el.id === id).states;
    };

    const handleState = (id) => {
        state = id;
    };
</script>

<Breadcrumb
    {region} {country} {state}
    on:region={() => handleRegion(null)}
    on:country={() => handleRegion(region)}
    on:state={() => handleCountry(country)} />
<div class="sc__filters">
    <ul class="sc__filter filter--regions" class:filter--selected="{region}">
        {#each regions as regionEl}
        <li class="sc__filter__option" class:option--region="{regionEl.id === region}" on:click={() => handleRegion(regionEl.id)}>{regionEl.name}</li>
        {/each}
    </ul>
    {#if visibleCountries && visibleCountries.length > 0}
    <ul class="sc__filter filter--countries" class:filter--selected="{country}">
        {#each visibleCountries as countryEl}
        <li class="sc__filter__option" class:option--country="{countryEl.id === country}" on:click={() => handleCountry(countryEl.id)}>{countryEl.name}</li>
        {/each}
    </ul>
    {/if}
    {#if states && states.length > 0}
    <ul class="sc__filter filter--states" class:filter--selected="{state}">
        {#each states as stateEl}
        <li class="sc__filter__option" class:option--state="{stateEl.id === state}" on:click={() => handleState(stateEl.id)}>{stateEl.name}</li>
        {/each}
    </ul>
    {/if}
</div>